<template>
	<div>
		<p class="text-center font-weight-bold">
			เรื่อง นโยบายความเป็นส่วนตัว (Privacy Policy) <br>
			บริษัท โตโต้ (ประเทศไทย) จำกัด <br>
			————————————————————————
		</p>
		<br>
		<p class="text-center font-weight-bold" style="text-decoration: underline">บทนำ</p>

		<p class="text-indent">
			นโยบายความเป็นส่วนตัวนี้กำหนดหลักการของบริษัท โตโต้ (ประเทศไทย) จำกัด (“บริษัทฯ”) ในการจัดการข้อมูลส่วนบุคคลทั้งหมดที่บริษัทฯ ได้รับมาไม่ว่าจะโดยตรงหรือโดยอ้อม บริษัทฯ
			และพนักงานของบริษัทฯ หุ้นส่วนทางธุรกิจ คู่ค้าหรือบุคคลภายนอกที่ปฏิบัติงานให้หรือในนามของบริษัทฯ มุ่งมั่นที่จะคุ้มครองความเป็นส่วนตัวของท่าน
			และขอรับรองว่าข้อมูลส่วนบุคคลของท่านจะถูกประมวลผลโดยเป็นไปตามกฎหมายคุ้มครองข้อมูลที่บังคับใช้ซึ่งรวมถึงพระราชบัญญัติคุ้มครองข้อมูลส่วนบุคคล พ.ศ. 2562 (“พ.ร.บ.
			คุ้มครองข้อมูลส่วนบุคคล”) ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมจะถูกเก็บไว้อย่างปลอดภัยเสมอ <br>
			นโยบายความเป็นส่วนตัวนี้ประกอบด้วย
		</p>

		<ul>
			<li>ข้อมูลส่วนบุคคลคืออะไร</li>
			<li>บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลใดบ้าง</li>
			<li>บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</li>
			<li>บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมอย่างไร</li>
			<li>บริษัทฯ เปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมเมื่อใด</li>
			<li>บริษัทฯ ทำการโอนข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไปยังต่างประเทศหรือไม่</li>
			<li>บริษัทฯ แจ้งต่อท่านถึงข้อมูลส่วนบุคคลของท่านเมื่อใด</li>
			<li>ท่านมีสิทธิอะไรบ้างภายใต้พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล</li>
			<li>บริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไว้นานแค่ไหน</li>
			<li>บริษัทฯ ใช้มาตรการใดรักษาความปลอดภัยข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม</li>
			<li>บริษัทฯ ทำการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้เมื่อใด</li>
			<li>ติดต่อบริษัทฯ ได้อย่างไร</li>
		</ul>

		<br>
		<p class="text-indent">
			โปรดอ่านนโยบายความเป็นส่วนตัวนี้อย่างละเอียดเพื่อทำความเข้าใจถึงวิธีการที่บริษัทฯ จัดการข้อมูลส่วนบุคคลของท่านและสิทธิทั้งหลายที่ท่านมีเกี่ยวกับข้อมูลส่วนบุคคลของท่าน
			อนึ่ง นโยบายฉบับนี้อาจมีการเปลี่ยนแปลงได้ในระหว่างที่ท่านยังคงมีความสัมพันธ์กับบริษัทฯ ดังนั้น บริษัทฯ ขอแนะนำให้ท่านกลับมาอ่านนโยบายฉบับนี้เป็นครั้งคราว อย่างไรก็ตาม
			หากนโยบายฉบับนี้มีการเปลี่ยนแปลงอย่างมีนัยสำคัญซึ่งอาจกระทบสิทธิในข้อมูลส่วนบุคคลของท่าน บริษัทฯ จะดำเนินการแจ้งให้ท่านทราบโดยทันทีโดยการประกาศลงในเว็บไซต์ของบริษัทฯ
		</p>

		<p class="text-indent">
			เว็บไซต์ของบริษัทฯ อาจมีไฮเปอร์ลิงค์ (hyperlinks) ที่นำท่านเข้าสู่เว็บไซต์ซึ่งบุคคลภายนอกเป็นเจ้าของและเป็นคนดำเนินการ
			เว็บไซต์ของบุคคลภายนอกเหล่านี้มีนโยบายความเป็นส่วนตัวแยกต่างหากและมีโอกาสที่จะมีการใช้คุกกี้ (cookies) บริษัทฯ ขอแนะนำให้ท่านอ่านนโยบายความเป็นส่วนตัวเหล่านี้
			ซึ่งจะควบคุมการใช้ข้อมูลส่วนบุคคลที่ท่านอาจได้ส่งไปให้เมื่อเข้าสู่เว็บไซต์ ทั้งนี้ ข้อมูลส่วนบุคคลของท่านอาจถูกเก็บรวบรวมโดยคุกกี้ (cookies) บริษัทฯ
			จะไม่มีความรับผิดในกรณีใด ๆ ที่เกิดจากการเข้าเว็บไซต์ของบุคคลภายนอกดังกล่าว
		</p>

		<p class="text-indent">
			หากท่านมีข้อสงสัยหรือไม่เข้าใจส่วนใดในนโยบายความเป็นส่วนตัวนี้ หรือประสงค์จะใช้สิทธิในข้อมูลส่วนบุคคลของท่านที่บริษัทฯ เก็บรวบรวมไว้ โปรดติดต่อบริษัทฯ
			ตามรายละเอียดที่ให้ไว้ส่วน “ติดต่อบริษัทฯ ได้อย่างไร”
		</p>


		<span class="font-weight-bold" style="text-decoration: underline">1. ข้อมูลส่วนบุคคลคืออะไร</span><br>
		<p class="text-indent">
			ภายใต้ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล ข้อมูลส่วนบุคคล หมายถึง ข้อมูลใดที่เกี่ยวกับบุคคลซึ่งทำให้บริษัทฯ
			สามารถระบุตัวบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อมจากข้อมูลนั้นเพียงลำพังหรือเมื่อนำไปรวมกับตัวระบุอื่น ๆ ที่บริษัทฯ มีหรือสามารถเข้าถึงได้ตามสมควร
			แต่ไม่รวมถึงข้อมูลของผู้ถึงแก่กรรม ข้อมูลส่วนบุคคลสามารถแยกประเภทได้ดังต่อไปนี้
		</p>

		<ul>
			<li>
				ข้อมูลส่วนบุคคลทั่วไป หมายถึง ข้อมูลส่วนบุคคลใดที่ไม่ใช่ข้อมูลส่วนบุคคลที่อ่อนไหว เช่น ชื่อและนามสกุล เพศ
				วันเดือนปีเกิด อายุ สัญชาติ หมายเลขติดต่อ ภาพถ่าย ที่อยู่ อีเมล
			</li>
			<li>
				ข้อมูลส่วนบุคคลที่อ่อนไหว หมายถึง ประเภทเฉพาะของข้อมูลส่วนบุคคลภายใต้พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล
				ซึ่งประกอบด้วย ต้นกำเนิดเชื้อชาติหรือเผ่าพันธุ์ ความคิดเห็นทางการเมือง ความเชื่อทางลัทธิ ศาสนาหรือปรัชญา พฤติกรรมทางเพศ บันทึกประวัติอาชญากรรม
				ข้อมูลสุขภาพหรือข้อมูลความพิการ ข้อมูลสหภาพแรงงาน ข้อมูลพันธุกรรม ข้อมูลชีวภาพ หรือ
				ข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ประกาศกำหนดไว้ตามกฎหมาย
			</li>
		</ul>

		<div>
			<span class="font-weight-bold" style="text-decoration: underline">2. บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลใดบ้าง</span><br>
			<p class="text-indent">
				ประเภทของข้อมูลที่บริษัทฯ เก็บรวบรวมจากท่านอาจแตกต่างกันไปตามตัวตนของท่านและความสัมพันธ์ของท่านที่มีกับบริษัทฯ โดยข้อมูลส่วนบุคคลที่จะถูกเก็บรวบรวมอาจรวมถึง
			</p>
			ข้อมูลส่วนบุคคลทั่วไป

			<ul>
				<li>ชื่อ นามสกุล ชื่อเล่น ลายเซ็น สัญชาติ เพศ วันเดือนปีเกิด อายุ ภาพถ่าย อาชีพ และตำแหน่ง</li>
				<li>หมายเลขติดต่อ อีเมล ที่อยู่ LINE ID และบัญชีโซเชียลมีเดีย</li>
				<li>ทะเบียนรถ</li>
				<li>บัตรประจำตัวประชาชน (โดยไม่รวมถึงศาสนาและหมู่เลือด) พาสปอร์ต วีซ่าและใบอนุญาตทำงาน ใบขับขี่ ทะเบียนบ้าน สูติบัตร ทะเบียนสมรส
					ใบรับรองผลการตรวจเลือกเข้ารับราชการทหาร
				</li>
				และใบประกอบวิชาชีพ
				<li>รายละเอียดในเรซูเม่ (เช่น ประวัติการศึกษา และใบรับรองผลการเรียน)</li>
				<li>ประสบการณ์การทำงาน</li>
				<li>รายละเอียดบัญชีธนาคาร และสมุดบัญชีธนาคาร</li>
				<li>ภาพจากกล้องวงจรปิด</li>
				<li>ข้อมูลอื่น ๆ ที่ท่านมอบให้บริษัทฯ</li>
			</ul>

			<br>
			ข้อมูลส่วนบุคคลที่อ่อนไหว
			<ul>
				<li>บัตรประจำตัวประชาชนที่ปรากฏศาสนา และหมู่เลือด</li>
				<li>ประวัติอาชญากรรม</li>
				<li>ผลการทดสอบ ATK และประวัติการฉีดวัคซีน Covid-19</li>
				<li>รายละเอียดสุขภาพและผลการตรวจสุขภาพ</li>
				<li>อุณหภูมิของร่างกาย</li>
				<li>ผลตรวจสารเสพติดและแอลกอฮอล์</li>
				<li>การแพ้อาหาร</li>
				<li>ข้อมูลอื่นใดซึ่งกระทบต่อเจ้าของข้อมูลส่วนบุคคลในทำนองเดียวกันตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคล ประกาศกำหนดไว้ตามกฎหมาย</li>
			</ul>

			<p class="text-indent">
				หากท่านปฏิเสธหรือไม่สามารถที่จะส่งมอบข้อมูลส่วนบุคคลของท่านได้ตามที่บริษัทฯ ขอ บริษัทฯ อาจไม่สามารถสร้างความสัมพันธ์กับท่าน หรือเสนอสินค้าและ/หรือบริการของบริษัทฯ
				ให้กับท่านได้ รวมถึงการจ้างงานระหว่างบริษัทฯ กับท่านอีกทั้งประโยชน์และสวัสดิการอื่น ๆ
			</p>
		</div>

		<span class="font-weight-bold" style="text-decoration: underline">3. บริษัทฯ เก็บรวบรวมข้อมูลส่วนบุคคลของท่านอย่างไร</span><br>
		<p class="text-indent">
			บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากท่านโดยตรง แต่ในบางกรณี บริษัทฯ จะเก็บรวบรวมข้อมูลส่วนบุคคลของท่านจากแหล่งข้อมูลสาธารณะและ/หรือบุคคลภายนอกอื่น ๆ
			ซึ่งบริษัทฯ ขอรับรองว่าบริษัทฯ จะปฏิบัติตาม
			พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล อย่างเคร่งครัด บุคคลภายนอกอื่น ๆ อาจรวมถึงบริษัทย่อยของบริษัทฯ หุ้นส่วนทางธุรกิจที่ได้รับอนุญาต ผู้ให้บริการ หรือคู่ค้า
		</p>

		<div>
			<span class="font-weight-bold" style="text-decoration: underline">4. บริษัทฯ ใช้ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมอย่างไร</span><br>
			<p class="text-indent">
				บริษัทฯ เก็บรวบรวม ใช้ เปิดเผย โอน หรือประมวลผลข้อมูลส่วนบุคคลของท่านด้วยวิธีการที่ยุติธรรมและชอบด้วยกฎหมายในขอบเขตที่จำเป็นต่อการบรรลุวัตถุประสงค์ของบริษัทฯ
				โดยฐานทางกฎหมายรวมถึง
			</p>

			<ul>
				<li>บริษัทฯ ขอรับความยินยอมจากท่านในการใช้ข้อมูลส่วนบุคคลของท่าน</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อประโยชน์สำคัญต่อชีวิต (vital interest) หรือ เพื่อป้องกันหรือหลีกเลี่ยงอันตรายต่อชีวิต ร่างกาย
					หรือสุขภาพของบุคคล
				</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญา หรือ เพื่อดำเนินการตามคำขอของท่านก่อนเข้าทำสัญญา</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินภารกิจของบริษัทฯ
					เพื่อประโยชน์สาธารณะหรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่มอบให้แก่บริษัทฯ
				</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อประโยชน์อันชอบด้วยกฎหมาย (legitimate interest) ของบริษัทฯ หรือบุคคลภายนอก
					เว้นแต่ประโยชน์ดังกล่าวมีความสำคัญน้อยกว่าประโยชน์หรือสิทธิและเสรีภาพขั้นพื้นฐานของท่าน
				</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อการก่อตั้ง การเรียกร้อง การใช้สิทธิหรือการยกขึ้นต่อสู้ซึ่งข้อเรียกร้องทางกฎหมายกับท่าน</li>
				<li>บริษัทฯ เชื่อว่าการใช้ข้อมูลส่วนบุคคลของท่านเป็นการจำเป็นเพื่อการปฏิบัติตามหน้าที่ตามกฎหมายที่บริษัทฯ อยู่ภายใต้บังคับ</li>
			</ul>

			<br>
			บริษัทฯ อาจใช้ข้อมูลส่วนบุคคลของท่านเพื่อวัตถุประสงค์ดังต่อไปนี้ <br>

			4.1 สัญญาระหว่างบริษัทฯ กับท่าน
			<br>
			บริษัทฯ จะใช้หลักการปฏิบัติตามสัญญาที่ท่านเป็นคู่สัญญาในการใช้ข้อมูลส่วนบุคคลของท่าน ซึ่งบริษัทฯ อาจใช้
			ข้อมูลส่วนบุคคลของท่านในกรณีดังต่อไปนี้โดยขึ้นอยู่กับลักษณะของแต่ละสัญญาที่ท่านมีกับบริษัทฯ
			<br>
			<ul>
				<li>การสรรหาและคัดเลือกการสมัครงานของท่านก่อนเข้าทำสัญญาจ้างงาน</li>
				<li>การสร้าง การควบคุม การมอบอำนาจ การประสานงาน การร่วมมือ การบริหารจัดการ การสิ้นสุดและ</li>
				<li>การดำเนินการอื่น ๆ เกี่ยวกับความสัมพันธ์ในการจ้างงานระหว่างท่านกับบริษัทฯ</li>
				<li>การจัดอบรมและกิจกรรมอื่น ๆ ภายในบริษัทฯ</li>
				<li>การจัดหา ออกหรือเข้าทำสัญญา เปิดและรักษาบัญชีของท่านที่มีกับบริษัทฯ</li>
				<li>การใช้สิทธิหรือปฏิบัติตามหน้าที่ภายใต้สัญญาที่ทำขึ้น</li>
				<li>การบริหาร การนำไปใช้ การอำนวยความสะดวก การตรวจสอบ การบำรุงรักษา การจัดการ และการดำเนินงานเกี่ยวกับบริการของท่านต่อบริษัทฯ</li>
				<li>การจัดการปัญหาที่เกิดจากการซื้อและการใช้สินค้าและบริการของบริษัทฯ หรือ</li>
				<li>การชำระเงิน รวมถึงการเปิดเผยข้อมูลส่วนบุคคลให้แก่ผู้ให้บริการซึ่งเป็นบุคคลที่สามเพื่อการชำระเงินดังกล่าว และให้แก่สถาบันการเงินที่ได้รับอนุญาต</li>
			</ul>

			<br>
			4.2. ประโยชน์อันชอบด้วยกฎหมายของบริษัทฯ
			<br>
			บริษัทฯ จะใช้และประมวลผลข้อมูลส่วนบุคคลทั่วไปของท่านภายใต้ฐานประโยชน์อันชอบธรรมที่บริษัทฯ หรือบุคคลภายนอกยึดถือ
			เว้นแต่กรณีที่ประโยชน์ดังกล่าวสำคัญน้อยกว่าประโยชน์หรือสิทธิและเสรีภาพขั้นพื้นฐานของท่าน

			ตัวอย่างเช่น บริษัทฯ มีผลประโยชน์อันชอบด้วยกฎหมายที่ทำให้บริษัทฯ สามารถประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมในกรณีดังต่อไปนี้
			<br>
			<ul>
				<li>การติดต่อธุรกรรมทางธุรกิจ</li>
				<li>การเข้าทำสัญญา</li>
				<li>การเข้ารับและจัดส่งสินค้า</li>
				<li>การเก็บรวบรวมและประมวลผลข้อมูลส่วนบุคคลทั่วไปของท่าน ไม่รวมถึงข้อมูลส่วนบุคคลที่อ่อนไหว</li>
				<li>เพื่อการสอบสวนข้อร้องเรียนเกี่ยวกับผลิตภัณฑ์ หรือการประพฤติมิชอบของพนักงาน</li>
				<li>การรักษาความปลอดภัยของบุคคลและทรัพย์สินภายในบริเวณของบริษัท เช่น การใช้ระบบกล้องวงจรปิดรักษาความปลอดภัย หรือ</li>
				<li>การก่อตั้ง ใช้ หรือยกขึ้นสู้สิทธิเรียกร้องตามกฎหมาย หรือเพื่อดำเนินคดีตามกฎหมายที่เกี่ยวข้องกับข้อมูลส่วนบุคคลทั่วไปของท่าน</li>
			</ul>

			<br>
			4.3 การปฏิบัติตามกฎหมายของบริษัทฯ และข้อเรียกร้องทางกฎหมาย
			<br>
			บริษัทฯ จะใช้หลักวัตถุประสงค์ในการปฏิบัติตามกฎหมายเมื่อจำเป็นหรือสามารถใช้ได้ตามกฎหมายที่มีผลบังคับใช้กับบริษัทฯ ตัวอย่างเช่น บริษัทฯ
			จะใช้หลักการปฏิบัติตามกฎหมายหรือหน้าที่ตามกฎหมายในการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมในกรณีดังต่อไปนี้
			<br>

			<ul>
				<li>การประมวลผลข้อมูลส่วนบุคคลของเจ้าของข้อมูลส่วนบุคคลเพื่อวัตถุประสงค์ในการชำระเงินและภาษีตามพระราชบัญญัติการบัญชีและประมวลรัษฎากร</li>
				<li>เพื่อปฏิบัติตามคำแนะนำของหน่วยงานภายใต้กฎหมาย รวมถึงคำร้องขอที่ชอบด้วยกฎหมายให้เปิดเผยข้อมูลของหน่วยงานบังคับใช้กฎหมาย หรือหน่วยงานของรัฐอื่น ๆ</li>
			</ul>

			<br>
			บริษัทฯ อาจใช้หลักสิทธิเรียกร้องตามกฎหมายในการประมวลผลข้อมูลส่วนบุคคลที่อ่อนไหวของท่านเพื่อการก่อตั้ง
			ปฏิบัติตาม ใช้สิทธิ หรือยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมายกับท่าน หรือเพื่อดำเนินคดีฟ้องร้องเพื่อปกป้องประโยชน์ของบริษัทฯ

			<br>
			4.4 ความยินยอมของบริษัทฯ
			<br><br>
			บริษัทฯ จะประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมบนฐานความยินยอม โดยเฉพาะอย่างยิ่งในกรณีที่
			การประมวลผลข้อมูลของบริษัทฯ อาจมีผลกระทบต่อข้อมูลส่วนบุคคลที่อ่อนไหวของท่าน
			<br>
			บริษัทฯ อาจแจ้งต่อท่านถึงวัตถุประสงค์ในการใช้ข้อมูลส่วนบุคคลของบริษัทฯ และจะขอรับความยินยอมหรือความยินยอมโดยชัดแจ้งจากท่านในกรณีดังต่อไปนี้
			<ul>
				<li>ในกรณีที่บริษัทฯ ไม่มีฐานทางกฎหมายอื่นในการประมวลผลข้อมูลส่วนบุคคลทั่วไปหรือข้อมูลส่วนบุคคลที่อ่อนไหวของท่าน เช่น การประมวลผลข้อมูลสุขภาพ
					การประมวลผลบันทึกประวัติอาชญากรรม การประมวลผลสำเนา
					บัตรประจำตัวประชาชนซึ่งมีข้อมูลส่วนบุคคลที่อ่อนไหว
				</li>
				<li>ในกรณีที่บริษัทฯ มีความประสงค์จะโอนข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไปยังต่างประเทศโดยที่ประเทศปลายทางมีมาตรฐานความเป็นส่วนตัวของข้อมูลที่ต่ำกว่า หรือ
				</li>
				<li>ในกรณีที่ท่านเป็นผู้เยาว์ คนเสมือนคนไร้ความสามารถหรือคนไร้ความสามารถซึ่งเป็นกรณีที่ต้องได้รับความยินยอมจากผู้แทนโดยชอบธรรม
					ผู้พิทักษ์หรือผู้อนุบาลของท่านแล้วแต่กรณี
				</li>
			</ul>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">5. บริษัทฯ เปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านที่เก็บรวบรวมเมื่อใด</span><br>
			<p class="text-indent">
				บริษัทฯ อาจเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมให้แก่บุคคลภายนอกเพื่อให้บรรลุวัตถุประสงค์ตามที่ได้ระบุไว้เมื่อทำการเก็บรวบรวมข้อมูลส่วนบุคคล
				บุคคลภายนอกที่บริษัทฯ อาจเปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมอาจรวมถึง
			</p>

			<ul>
				<li>บริษัทในกลุ่มและบริษัทย่อย</li>
				<li>หน่วยงานของรัฐบาล หน่วยงานกำกับดูแลหรือตุลาการ</li>
				<li>ธนาคารพาณิชย์</li>
				<li>โรงพยาบาล</li>
				<li>บริษัทประกัน หรือ</li>
				<li>หุ้นส่วนทางธุรกิจที่ได้รับอนุญาต ผู้ให้บริการหรือคู่ค้า</li>
			</ul>

			<br>
			<p class="text-indent">เมื่อบริษัทฯ เปิดเผยหรือแบ่งปันข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมให้แก่บุคคลภายนอก บริษัทฯ
				จะดำเนินการกำกับดูแลบุคคลภายนอกที่จำเป็นและเหมาะสมเพื่อรับรองการประมวลผลข้อมูลส่วนบุคคลที่ถูกเปิดเผยหรือแบ่งปันให้เป็นไปโดยปลอดภัย เช่น
				การเข้าทำสัญญาเกี่ยวกับการประมวลผลข้อมูลส่วนบุคคลกับบุคคลภายนอก</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">6. บริษัทฯ ทำการโอนข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไปยังต่างประเทศหรือไม่</span><br>
			<p class="text-indent">
				บริษัทฯ อาจโอนข้อมูลส่วนบุคคลทั่วไปของท่านที่ได้ถูกเก็บรวบรวมและ/หรือข้อมูลส่วนบุคคลที่อ่อนไหวของท่าน
				ที่ได้ถูกเก็บรวบรวมให้แก่บริษัทในกลุ่มหรือบุคคลภายนอกซึ่งอยู่นอกประเทศไทยเพื่อวัตถุประสงค์ตามที่นโยบาย
				ความเป็นส่วนตัวนี้กำหนดไว้
			</p>

			<p class="text-indent">
				บริษัทฯ
				จะโอนข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไปยังประเทศที่มีมาตรฐานการคุ้มครองข้อมูลหรือกฎหมายที่เกี่ยวกับความเป็นส่วนตัวเหมาะสมเพียงพอตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลเห็นสมควร
				ในกรณีที่มาตรฐานความปลอดภัยของข้อมูลถือว่าไม่เพียงพอ บริษัทฯ จะมีการป้องกันที่เหมาะสมเพื่อปกป้องคุ้มครองผลประโยชน์ของท่าน
				หรือให้มีการโอนข้อมูลได้ต่อเมื่อเป็นไปตามข้อยกเว้นข้อใดข้อหนึ่งที่พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล กำหนดไว้เท่านั้น ข้อยกเว้นดังกล่าวประกอบด้วยกรณีที่
			</p>

			<ul>
				<li>การโอนข้อมูลเป็นการจำเป็นเพื่อปฏิบัติตามกฎหมาย</li>
				<li>
					ท่านได้ให้ความยินยอมอย่างชัดแจ้งในการโอนข้อมูลภายหลังจากที่ท่านได้รับทราบถึงความเสี่ยงที่อาจเกิดขึ้นจากการโอนข้อมูลดังกล่าวซึ่งไม่มีมาตรฐานความปลอดภัยของข้อมูลหรือการป้องกันที่เหมาะสมเพียงพอ
				</li>
				<li>การโอนข้อมูลเป็นการจำเป็นเพื่อปฏิบัติตามสัญญาที่มีกับท่านหรือเพื่อการดำเนินมาตรการก่อนทำสัญญา (pre-contractual measures) ตามที่ท่านขอ</li>
				<li>การโอนข้อมูลเป็นการจำเป็นเพื่อการสิ้นสุดหรือปฏิบัติตามสัญญาระหว่างบริษัทฯ และบุคคลธรรมดาหรือนิติบุคคลอื่นเพื่อประโยชน์ของท่าน</li>
				<li>การโอนข้อมูลเป็นการจำเป็นเพื่อปกป้องประโยชน์ที่สำคัญต่อชีวิต (vital interests) ของท่านหรือบุคคลอื่น ในกรณีที่เจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมได้
					หรือ
				</li>
				<li>การโอนข้อมูลจำเป็นเพื่อเหตุผลสำคัญที่เป็นประโยชน์ต่อสาธารณะ</li>
			</ul>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">7. บริษัทฯ แจ้งต่อท่านถึงข้อมูลส่วนบุคคลของท่านเมื่อใด</span><br>
			<p class="text-indent">
				บริษัทฯ จะแจ้งให้ท่านทราบทุกครั้งก่อนหรือในขณะที่ทำการเก็บรวบรวมข้อมูลส่วนบุคคลของท่านถึงวัตถุประสงค์
				ในการประมวลผลข้อมูลส่วนบุคคลของท่าน ยกเว้นในกรณีที่บริษัทฯ ไม่จำเป็นต้องแจ้งให้ท่านทราบถึงวัตถุประสงค์
				ในการประมวลผลข้อมูลของบริษัทฯ เช่นในกรณีที่
			</p>

			<ul>
				<li>ท่านทราบถึงวัตถุประสงค์ใหม่หรือรายละเอียดของการประมวลผลข้อมูลส่วนบุคคลของท่านแล้ว</li>
				<li>บริษัทฯ เชื่อว่าการแจ้งวัตถุประสงค์ใหม่ดังกล่าวหรือรายละเอียดของการประมวลผลข้อมูลของบริษัทฯ
					ไม่สามารถกระทำได้หรือเป็นอุปสรรคต่อการใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่าน
					โดยเป็นกรณีที่บริษัทฯ ได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิ เสรีภาพและผลประโยชน์ของท่าน
				</li>
				<li>เป็นกรณีเร่งด่วนที่จำเป็นต้องใช้หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมตามกฎหมายและบริษัทฯ</li>
				<li>ได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองผลประโยชน์ของท่าน หรือ</li>
				<li>บริษัทฯ รับทราบหรือได้มาซึ่งข้อมูลส่วนบุคคลของท่านโดยการปฏิบัติหน้าที่ อาชีพหรือวิชาชีพ และบริษัทฯ
					ได้รักษาวัตถุประสงค์ใหม่หรือรายละเอียดบางส่วนไว้เป็นความลับตามที่กฎหมายกำหนด
				</li>
			</ul>


		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">8. ท่านมีสิทธิอะไรบ้างภายใต้ พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล</span><br>
			<p class="text-indent">
				ภายใต้พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล ท่านมีสิทธิตามกฎหมายเกี่ยวกับข้อมูลส่วนบุคคลของท่านดังต่อไปนี้
			</p>

			1) สิทธิในการเข้าถึงข้อมูล <br>
			ท่านมีสิทธิเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของท่านที่บริษัทฯ เก็บไว้ หรือท่านสามารถขอให้บริษัทฯ เปิดเผยแหล่งที่มาที่บริษัทฯ
			ได้รับข้อมูลส่วนบุคคลของท่านซึ่งท่านไม่ได้ให้ความยินยอม
			บริษัทฯ จะดำเนินการตามคำขอของท่านโดยเร็วที่สุด โดยจะไม่เกินสามสิบ (30) วันหลังจากที่ได้รับคำขอจากท่าน
			<br>

			2) สิทธิในการโอนย้ายข้อมูล <br>
			ท่านมีสิทธิขอให้บริษัทฯ โอนข้อมูลส่วนบุคคลของท่านไปยังบุคคล/องค์กรอื่นหรือขอดูข้อมูลส่วนบุคคลที่ได้
			ถูกเก็บรวบรวมที่บริษัทฯ ได้โอนไปยังบุคคล/องค์กรอื่น เว้นแต่บริษัทฯ ไม่สามารถดำเนินการตามคำขอของท่านด้วยเหตุทางเทคนิค
			<br>

			3) สิทธิในการคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม <br>
			ท่านมีสิทธิคัดค้านการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม เว้นแต่มีเหตุที่ทำให้ท่านไม่สามารถคัดค้านได้ เหตุดังกล่าวอาจรวมถึงกรณีที่บริษัทฯ
			มีประโยชน์อันชอบด้วยกฎหมายหรือเมื่อการประมวลผล
			ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมเป็นการปฏิบัติตาม การใช้สิทธิ หรือยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้อง
			ทางกฎหมายหรือเพื่อประโยชน์สาธารณะของบริษัทฯ
			<br>

			4) สิทธิในการขอลบข้อมูล <br>
			ท่านมีสิทธิขอให้บริษัทฯ ลบ ทำลาย หรือทำให้ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมเป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ (anonymize) ในกรณีดังต่อไปนี้

			<ul class="mb-0">
				<li>ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไม่มีความจำเป็นต่อวัตถุประสงค์ตามที่ได้เก็บรวบรวม ใช้ หรือเปิดเผยอีกต่อไป</li>
				<li>ท่านได้ถอนความยินยอมของท่านจากการเก็บรวบรวม ใช้ หรือเปิดเผยที่กระทำบนความยินยอมของท่านและบริษัทฯ ไม่มีฐานทางกฎหมายในการเก็บรวบรวม ใช้</li>
				<li>หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้ ถูกเก็บรวบรวม</li>
				<li>ท่านได้คัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมและบริษัทฯ ไม่มีฐานทางกฎหมายที่จะปฏิเสธคำขอของท่าน และ/หรือ</li>
				<li>ข้อมูลส่วนบุคคลของท่านถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยมิชอบด้วยกฎหมายภายใต้พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล</li>
			</ul>

			5) สิทธิในการจำกัดการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม <br>
			ท่านมีสิทธิขอให้บริษัทฯ จำกัดการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมในกรณีดังต่อไปนี้

			<ul class="mb-0">
				<li>ข้อมูลส่วนบุคคลของท่านอยู่ระหว่างการดำเนินการตรวจสอบว่าข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมมีความถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ทำให้เข้าใจผิด</li>
				<li>ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมสมควรต้องถูกลบหรือทำลายเนื่องจากไม่เป็นไปตามกฎหมาย
					แต่ท่านขอให้ระงับการใช้แทน</li>
				<li>ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไม่จำเป็นต้องเก็บรักษาไว้เพื่อวัตถุประสงค์ที่ได้เก็บรวบรวม ใช้ หรือเปิดเผยอีกต่อไป แต่ท่านมีความจำเป็นต้องขอให้ทำการเก็บรักษาไว้เพื่อการก่อตั้ง การปฏิบัติตาม
					การใช้สิทธิ หรือยกขึ้นต่อสู้ซึ่งข้อเรียกร้องทางกฎหมาย และ/หรือ</li>
				<li>บริษัทฯ อยู่ในระหว่างรอการยืนยันความถูกต้องเพื่อปฏิเสธคำขอคัดค้านการเก็บรวบรวม ใช้หรือเปิดเผย ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม</li>
			</ul>

			<br>
			6) สิทธิในการขอแก้ไขข้อมูล <br>
			ท่านมีสิทธิขอแก้ไขข้อมูลส่วนบุคคลที่ไม่ถูกต้องเพื่อให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ทำให้เข้าใจผิด
			หากบริษัทฯ ปฏิเสธคำขอแก้ไขข้อมูลส่วนบุคคลของท่าน บริษัทฯ จะบันทึกการปฏิเสธพร้อมด้วยเหตุผล

			<br>
			7) สิทธิในการยื่นเรื่องร้องเรียน <br>
			ท่านมีสิทธิยื่นร้องเรียนในกรณีที่บริษัทฯ ผู้ประมวลผลข้อมูลของบริษัทฯ รวมทั้งพนักงานหรือผู้รับเหมาของบริษัทฯ ไม่ปฏิบัติตามพ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล หรือประกาศอื่น ๆ
			ภายใต้พ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล

			<br>
			8) สิทธิในการถอนความยินยอม <br>
			ท่านสามารถถอนความยินยอมของท่านได้ตลอดเวลา เว้นแต่บริษัทฯ มีฐานทางกฎหมายที่จะปฏิเสธคำขอของท่าน โดยบริษัทฯ
			ขอเรียนให้ท่านทราบว่าการถอนความยินยอมของท่านอาจมีผลกระทบต่อความสัมพันธ์ที่บริษัทฯ
			มีกับท่าน หรือสินค้าและ/หรือบริการที่ท่านได้จะรับจากบริษัทฯ เนื่องจากข้อมูลส่วนบุคคลที่มีอยู่หลังจากที่ถอน
			ความยินยอมแล้วอาจไม่เพียงพอต่อการให้บริการของบริษัทฯ ครบถ้วนตามที่ท่านต้องการ หรือบริษัทฯ อาจต้อง
			ใช้เวลาในการขอข้อมูลเพิ่มเติมจากท่าน
			<br>
			หากท่านเปลี่ยนใจเกี่ยวกับการที่บริษัทฯ มีหรือประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมในประการใด และท่านประสงค์จะถอนความยินยอมของท่าน ท่านสามารถแจ้งบริษัทฯ
			ได้ตลอดเวลาโดยทำตามขั้นตอนการถอนความยินยอมของบริษัทฯได้ที่ <span class="font-weight-bold">ส่วนทรัพยากรบุคคล บริษัท โตโต้ (ประเทศไทย) จำกัด เบอร์โทร 036-382-750 ต่อ 208</span>

			<p class="text-indent">
				เมื่อได้รับคำขอใช้สิทธิของท่าน บริษัทฯ อาจทำการขอข้อมูลเพิ่มเติมจากท่านเพื่อยืนยันตัวตนและสิทธิของท่านซึ่งเป็นส่วนหนึ่งของมาตรการรักษาความปลอดภัยของบริษัทฯ แล้วแต่กรณี
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">9. บริษัทฯ เก็บรักษาข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไว้นานแค่ไหน</span><br>
			<p class="text-indent">
				บริษัทฯ จะเก็บรักษาข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไว้นานเท่าที่จำเป็นเพื่อวัตถุประสงค์ตามที่ได้ระบุไว้เมื่อทำการเก็บรวบรวม กล่าวคือ
				ระยะเวลาเก็บรักษาข้อมูลจะแตกต่างกันตามประเภทของข้อมูลส่วนบุคคลของท่านที่ได้
				ถูกเก็บรวบรวมและวัตถุประสงค์หรือเหตุผลที่บริษัทฯ ทำการเก็บรวบรวม ในกรณีที่บริษัทฯ จำเป็นต้องเก็บรักษา
				ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมไว้ต่อไปอีกเพื่อปฏิบัติหน้าที่ตามกฎหมาย หรือในกรณีที่มีการใช้สิทธิเรียกร้องหรือข้อร้องเรียนใดที่มีเหตุอันควรให้บริษัทฯ
				ต้องเก็บรักษาข้อมูลส่วนบุคคลของท่านไว้ หรือมีเหตุผลข้อบังคับหรือทางเทคนิคใด บริษัทฯ จะยังคงปกป้องคุ้มครองข้อมูลส่วนบุคคลที่ได้ถูกเก็บรวบรวมต่อไป
			</p>

			<p class="text-indent">
				บริษัทฯ มีกระบวนการเกี่ยวกับระยะเวลาเก็บรักษาข้อมูลของบริษัทฯ ซึ่งได้รับการทบทวนอยู่อย่างสม่ำเสมอโดยพิจารณาถึงวัตถุประสงค์ในการประมวลผลข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมและฐานทางกฎหมายสำหรับการกระทำดังกล่าว
			</p>

			<p class="text-indent">
				บริษัทฯ อาจต้องเก็บบันทึกภาพและวิดีโอจากระบบกล้องโทรทัศน์วงจรปิดที่บริษัทฯ ติดตั้งไว้เพื่อความปลอดภัยของบุคคลและทรัพย์สินภายในสถานที่ของบริษัทฯ เป็นเวลา 30 วัน
			</p>

			<p class="text-indent">
				บริษัทฯ จะลบ ทำลาย ทำให้เป็นข้อมูลที่ไม่สามารถระบุตัวตนได้ (anonymize) โดยถาวรหรือกำจัดข้อมูลส่วนบุคคลที่ได้ถูกเก็บรวบรวมทั้งหมดเมื่อพ้นระยะเวลาเก็บรักษาข้อมูล
				หรือเมื่อจำเป็นต้องปฏิบัติตามคำขอให้ลบข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม
			</p>

			<p class="text-indent">
				หากท่านมีข้อสงสัย โปรดติดต่อบริษัทฯ ตามรายละเอียดที่ให้ไว้ในส่วน “ติดต่อบริษัทฯ ได้อย่างไร”
			</p>

		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">10. บริษัทฯ ใช้มาตรการใดรักษาความปลอดภัยข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวม</span><br>
			<p class="text-indent">
				บริษัทฯ มีการใช้มาตรการต่าง ๆ เพื่อรักษาให้ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมอยู่ในความปลอดภัย รวมถึงเพื่อป้องกันการสูญเสียหรือความเสียหายและการเก็บรวบรวม เข้าถึง
				ใช้ เปลี่ยนแปลง แก้ไข เปิดเผยหรือประมวลผล
				ข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมโดยมิชอบด้วยกฎหมายหรือไม่ได้รับอนุญาต อนึ่ง มาตรการรักษาความปลอดภัยของบริษัทฯ
				ซึ่งบังคับใช้กับการประมวลผลข้อมูลทุกประเภทไม่ว่าข้อมูลส่วนบุคคลที่ถูกเก็บรวบรวมจะถูกประมวลผลทางอิเล็กทรอนิกส์ หรือในรูปแบบฉบับพิมพ์ ประกอบด้วยการเข้ารหัส (encryption)
				และวิธีการรักษาความปลอดภัยอื่น ๆ
			</p>

			<p class="text-indent">
				บริษัทฯ มีการควบคุมให้พนักงานของบริษัทฯ และบุคคลภายนอกผู้ปฏิบัติงานในนามบริษัทฯ ต้องปฏิบัติตามพ.ร.บ. คุ้มครองข้อมูลส่วนบุคคล และมาตรฐานความเป็นส่วนตัวที่เหมาะสม
				รวมถึงมีหน้าที่ต้องป้องกันการรั่วไหลของข้อมูลส่วนบุคคลและใช้มาตรการรักษาความปลอดภัยที่เหมาะสมในการประมวลผลข้อมูลส่วนบุคคล
			</p>

			<p class="text-indent">
				บริษัทฯ มีการรักษากระบวนการและมาตรการรักษาความปลอดภัยของบริษัทฯ อยู่อย่างสม่ำเสมอ
				และจะทำการแก้ไขปรับปรุงอย่างทันทีเมื่อพบว่ามีประเด็นที่ต้องปรับปรุงในกระบวนการและมาตรการรักษาความปลอดภัยของบริษัทฯ
				โดยพิจารณาถึงกระบวนการและมาตรการรักษาความปลอดภัยทางวัตถุ ทางเทคนิคและระดับองค์กรเพื่อรับรองระดับ
				ความปลอดภัยของข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมที่เหมาะสมเพียงพอต่อความเสี่ยงที่เกี่ยวข้อง และเพื่อให้
				การประมวลผลข้อมูลดังกล่าวสามารถเก็บรักษาเป็นความลับ มีความมั่นคง ความพร้อมและความยืดหยุ่นสืบเนื่องไป
			</p>

			<p class="text-indent">
				บริษัทฯ ขอรับรองว่าข้อมูลส่วนบุคคลที่ได้ถูกเก็บรวบรวมจะถูกเก็บรักษาไว้อย่างปลอดภัยและมั่นคงด้วยมาตรฐาน
				ความปลอดภัยที่เคร่งครัดและเพียงพอ หากท่านมีเหตุให้เชื่อว่าข้อมูลส่วนบุคคลของท่านที่ได้ถูกเก็บรวบรวมถูกละเมิดหรือมีข้อสงสัยเกี่ยวกับนโยบายความเป็นส่วนตัวนี้
				โปรดติดต่อบริษัทฯ ตามรายละเอียดที่ให้ไว้ในส่วน “ติดต่อบริษัทฯ ได้อย่างไร”
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">11. บริษัทฯ ทำการเปลี่ยนแปลงนโยบายความเป็นส่วนตัวนี้เมื่อใด</span><br>
			<p class="text-indent">
				บริษัทฯ ขอสงวนสิทธิในการเปลี่ยนแปลง แก้ไข หรือปรับปรุงนโยบายความเป็นส่วนตัวนี้ให้เป็นปัจจุบันได้ตลอดเวลาที่บริษัทฯ เห็นสมควร โดยบริษัทฯ จะแจ้งการเปลี่ยนแปลงต่าง ๆ
				ให้ท่านทราบผ่านทางเว็บไซต์ของบริษัทฯ ซึ่งท่านสามารถตรวจสอบได้ตลอดเวลา
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">12. ติดต่อบริษัทฯ ได้อย่างไร</span><br>
			<p class="text-indent">
				หากท่านมีความคิดเห็น คำแนะนำ คำถามหรือต้องการร้องเรียนหรือใช้สิทธิเกี่ยวกับข้อมูลส่วนบุคคลของท่าน โปรดติดต่อบริษัทฯ ได้ที่ ส่วนทรัพยากรบุคคล บริษัท โตโต้ (ประเทศไทย) จำกัด
				เบอร์โทร 036-382-750 ต่อ 208 หรือทางอีเมลได้ที่ คุณภัคนัฐ รามนัฏ [ pakkanut.rammanut@toto.com ] หรือทางเว็บไซต์ของบริษัทฯ ได้ที่ https://th.toto.com
			</p>
		</div>

		<div>
			<br>
			<p class="text-right font-weight-bold">
				บริษัท โตโต้ (ประเทศไทย) จำกัด
				<br>
				1 มิถุนายน พ.ศ. 2565
			</p>
		</div>
		<br><br><br>
	</div>
</template>

<script>
export default {
  name: "PrivacyPolicyTH"
}
</script>

<style scoped>
.text-indent {
	text-indent: 60px;
}

.font-weight-bold {
	font-weight: 600 !important;
}
</style>