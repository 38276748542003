<template>
	<div>
		<p class="text-center font-weight-bold">
			Topic : Privacy Policy <br>
			TOTO (Thailand) Co., Ltd. <br>
			————————————————————————
		</p>
		<br>
		<p class="text-center font-weight-bold" style="text-decoration: underline">Introduction</p>

		<p class="text-indent">
			This Privacy Policy prescribes TOTO (Thailand) Co., Ltd. (“the Company”, “we”, “us” or “our”) fundamental principles in handling all personal data obtained, either
			directly or indirectly, by us. The Company and all our employees, business partners, vendors or other third parties who perform work for or on behalf of us are fully
			committed to protect your privacy and ensure that your personal data will be processed in accordance with the applicable data protection laws including the Personal
			Data Protection Act, B.E. 2562 (the “PDPA”). Your collected personal data will always be kept safe and secure.<br>
			This Privacy Policy comprises of:
		</p>

		<ul>
			<li>What is personal data?</li>
			<li>What personal data do we collect?</li>
			<li>How do we collect your personal data?</li>
			<li>How do we use your collected personal data?</li>
			<li>When do we disclose or share your collected personal data?</li>
			<li>Do we transfer your collected personal data overseas?</li>
			<li>When do we inform you about your personal data?</li>
			<li>What are your rights under the PDPA?</li>
			<li>How long do we keep your collected personal data for?</li>
			<li>What measures do we use to secure your collected personal data?</li>
			<li>When do we make changes to this Privacy Policy?</li>
			<li>How to contact us?</li>
		</ul>

		<br>
		<p class="text-indent">
			Please carefully read this Privacy Policy to understand how we handle your personal data and your rights to your personal data. This Privacy Policy is subject to change
			at any time while our relationship with you continues. So, you should come back and read this Privacy Policy from time to time. If there is any significant change to
			our Privacy Policy which may affect the rights to your personal data, we will inform you without delay by announcing on our website.
		</p>

		<p class="text-indent">
			Our website may contain hyperlinks to websites owned and operated by third parties. These third-party websites have their own privacy policies, and are also likely to
			use cookies. We recommend that you review the third-parties’ policies which will govern the use of personal data which you may submit when visiting these websites and
			which may also be collected by cookies. We do not accept any liability for using such third-party websites.
		</p>

		<p class="text-indent">
			If you have questions or do not understand any part of this Privacy Policy or wish to exercise your rights relating to your collected personal data, please do not
			hesitate to contact us at the provided details in the “How to contact us?” section herein.
		</p>


		<span class="font-weight-bold" style="text-decoration: underline">1. What is personal data?</span><br>
		<p class="text-indent">
			Under the PDPA, a personal data means any data or information relating to an individual which enable us to identify such individual, whether directly or indirectly,
			from that data or information alone or in a combination with other identifiers we possess or can reasonably access, except information of the deceased. The personal
			data can be categorised as follows:
		</p>

		<ul>
			<li>
				General personal data – means any personal data which is not sensitive personal data e.g. name and surname, gender, date of birth, age, nationality, contact number,
				photo, address, email address.
			</li>
			<li>
				Sensitive personal data – means a special category of personal data under the PDPA consisting of racial or ethnic origin, political opinions, cult, religious or
				philosophical beliefs, sexual behaviour, criminal records, health data or disability condition, trade union information, genetic data, biometric data or any data
				which may affect the data subject in the same manner, as prescribed by the Personal Data Protection Committee
			</li>
		</ul>

		<div>
			<span class="font-weight-bold" style="text-decoration: underline">2. What personal data do we collect?</span><br>
			<p class="text-indent">
				The type of personal data we collect from you may be different depending on who you are and your relationship with us. The collected personal data may include:
			</p>
			General personal data

			<ul>
				<li>Name, surname, nickname, signature, nationality, gender, date of birth, age, photo, occupation and position</li>
				<li>Contact number, email address, address, Line ID and social media account</li>
				<li>Vehicle registration plate</li>
				<li>Identification card (excluding religion and blood group), passport, visa and work permit, driving license, house registration, birth certificate, marriage
					certificate, certificate of military service and professional license
				</li>
				<li>Resume details (e.g. educational background and transcript)</li>
				<li>Working experience</li>
				<li>Bank account details and bank book</li>
				<li>CCTV footage</li>
				<li>Other information that you have given us</li>
			</ul>

			<br>
			Sensitive personal data
			<ul>
				<li>ID card showing religion and blood group</li>
				<li>Criminal record</li>
				<li>ATK test result and Covid-19 vaccination history</li>
				<li>Health condition details and health check-up result</li>
				<li>Body temperature</li>
				<li>Drug and alcohol test result</li>
				<li>Food allergies</li>
				<li>Any data which may affect the data subject in the same manner, as prescribed by the Personal Data Protection Committee</li>
			</ul>

			<p class="text-indent">
				If you do not or are unable to provide your personal data which we require, we may not be able to establish a relationship with you or offer you our products and/or
				services including our employment with you and other benefits and welfare.
			</p>
		</div>

		<span class="font-weight-bold" style="text-decoration: underline">3. How do we collect your personal data?</span><br>
		<p class="text-indent">
			We will collect your personal data directly from you, but sometimes from publicly available sources and/or from other third parties, provided that we will ensure that
			we fully comply with the PDPA.
			Those other third parties may include our subsidiaries, authorised business partners, service providers or vendors.
		</p>

		<div>
			<span class="font-weight-bold" style="text-decoration: underline">4. How do we use your collected personal data?</span><br>
			<p class="text-indent">
				We collect, use, disclose, transfer or process your personal data by fair and lawful means to the extent necessary to achieve our purposes. The lawful basis
				includes:
			</p>

			<br>
			a) obtaining your consent to use your personal data; <br>
			b) believing that the use of your personal data is of vital interest or to prevent or avoid danger to a person’s life, body or health; <br>
			c) believing that the use of your personal data is necessary for the performance of a contract to which you are a party or in order to take steps at your request prior
			to
			entering into a contract; <br>
			d) believing that the use of your personal data is necessary for the performance of our task carried out in the public interest or in the exercise of official authority
			vested in us; <br>
			e) believing that the use of your personal data is necessary for the legitimate interests pursued by us or by a third party, unless the interests are overridden by your
			interests or fundamental rights and freedoms; <br>
			f) believing that the use of your personal data is necessary for the establishment, complaint, exercise or defence of legal claims against you; <br>
			g) believing that the use of your personal data is necessary for compliance with a legal obligation to which we are subject.
			We may use your personal data for purposes as follows:

			<br>
			We may use your personal data for purposes as follows:<br>

			4.1 4.1 Our contract with you
			<br>
			We will rely on the performance of contracts to which you are a party to use your personal data. Depending on the nature of each contract with us, we may use your
			personal data for the following reasons:
			<br>
			<ul>
				<li>Processing recruitment and selection regarding your employment application before entering into the employment agreement;</li>
				<li>Establishing, controlling, delegating, coordinating, collaborating, managing, concluding and other actions with regards to your employment relationship with
					us;
				</li>
				<li>Arranging training and other activities within organisation;</li>
				<li>Procuring, issuing or executing contracts, creating and maintaining your account with us;</li>
				<li>Exercising rights or performing obligations under executed contracts;</li>
				<li>Administering, implementing, facilitating, monitoring, maintaining, managing and operating your services with us;</li>
				<li>Handling problems arising from the purchase and use of our goods and services; or</li>
				<li>Processing payment, including the disclosure of personal data to third party service providers to process such payment and to the authorised financial
					institutions.
				</li>
			</ul>

			<br>
			4.2. Our legitimate interests
			<br>
			We may rely on the purpose of legitimate interests pursued by us or by a third party which require us to use and process your general personal data, except where such
			interests are overridden by your interests or fundamental rights and freedoms.

			For instance, we have legitimate interests which allow us to process your collected personal data in the following circumstances:
			<br>
			<ul>
				<li>Communicating business transactions;</li>
				<li>Executing agreements;</li>
				<li>Picking up and delivering the products;</li>
				<li>Collecting and processing general personal data not including sensitive personal data for investigation of complaints or employees’ misconducts;</li>
				<li>Maintaining security and safety of persons and properties on our premises i.e. using of CCTV surveillance system; or</li>
				<li>Establishing, exercising or defending our legal rights or for the purposes of legal proceedings involving your general personal data.</li>
			</ul>

			<br>
			4.3 Our legal compliances and legal claims
			<br>
			We will rely on the purpose of legal compliance when it is required or allowed by any applicable laws to which we are subject. For instance, we rely on legal
			compliance or legal obligation grounds to process your collected personal data in the following circumstances:
			<br>

			<ul>
				<li>Processing personal data of data subjects for the purposes of payment and taxes in compliance with the Accounting Act and Revenue Code;</li>
				<li>Following the instructions of the authorities under the law, including following the lawful requests for disclosure made by the law enforcement authorities or
					other government agencies.
				</li>
			</ul>

			<br>
			We may rely on the legal claims basis to process your sensitive personal data to establish, comply, exercise or defend legal claims against you or initiate litigation
			action to protect our interests.

			<br>
			4.4 Consents
			<br><br>
			We will process your collected personal data on grounds of consents; especially, in the case where our processing activities have potential impact on your sensitive
			personal data.

			<br>
			We may inform you of the objectives of our personal data usage and request your consent or explicit consent to process your collected personal data in the following
			circumstances:

			<ul>
				<li>When we do not have other lawful grounds to process your general personal data or sensitive personal data e.g. processing health information, processing
					criminal records, processing copies of ID cards containing sensitive personal data;
				</li>
				<li>When we intend to transfer your collected personal data overseas and the destination country has lesser data privacy standards; or</li>
				<li>When you are classified as a minor, quasi-incompetent or incompetent of which the consent will be requested from your legal representatives, guardians or
					curators, as the case may be.
				</li>
			</ul>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">5. When do we disclose or share your collected personal data?</span><br>
			<p class="text-indent">
				We may disclose to or share your collected personal data with other third parties to achieve the specific purposes for which the personal data was collected. The
				third parties who we may disclose or share your collected personal data with may include:
			</p>

			<ul>
				<li>Group companies and subsidiaries;</li>
				<li>Governmental agencies, regulatory or judicial authorities;</li>
				<li>Commercial banks;</li>
				<li>Hospitals</li>
				<li>Insurance company; or</li>
				<li>Authorised business partners, service providers or vendors.</li>
			</ul>

			<br>
			<p class="text-indent">
				When we disclose or share your collected personal data with any third parties, we will conduct necessary and appropriate supervision of the third parties to ensure
				safe processing of disclosed or shared personal data, by, for instance, entering into an agreement regarding the processing of personal data with the third parties.
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">6. Do we transfer your collected personal data overseas?</span><br>
			<p class="text-indent">
				We may transfer your collected general personal data and/or sensitive personal data to group companies or third parties located in countries outside Thailand for
				the purposes prescribed in this Privacy Policy.
			</p>

			<p class="text-indent">
				We will only transfer your collected personal data to a country that, in the view of the Thai Personal Data Protection Commission, has adequate data protection or
				privacy laws. Where such data security standards are deemed inadequate, we will provide appropriate safeguards to protect your interest or the transfer will take
				place if one of the exceptions defined by the PDPA is met. The exceptions are where:
			</p>

			<ul>
				<li>the transfer is necessary for compliance with the law;</li>
				<li>you have explicitly consented to the proposed transfer after having been informed of the possible risks of such transfer due to the absence of adequate security
					standards or safeguards;
				</li>
				<li>the transfer is necessary for the performance of a contract with you or the implementation of pre- contractual measures taken at your request;</li>
				<li>the transfer is necessary for the conclusion or performance of a contract in your interest between us and another natural or legal person;</li>
				<li>the transfer is necessary to protect your vital interests or those of other persons, and the data subject is incapable of giving consent; or</li>
				<li>the transfer is necessary for important reasons of public interest.</li>
			</ul>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">7. When do we inform you about your personal data?</span><br>
			<p class="text-indent">
				Before or at the time of collecting your personal data, we will always inform you of our purposes of processing your personal data. Only in some circumstances, it
				is not necessary for us to inform you of our processing purposes, such as when:
			</p>

			<ul>
				<li>you are already aware of such new purposes or details of processing of your personal data;</li>
				<li>we believe that notice of such new purposes or the details of our processing is impossible or will obstruct the use or disclosure of your personal data, where
					we have taken suitable measures to protect your rights, freedoms and interests;
				</li>
				<li>it is urgent to use or disclose your collected personal data as required by law and we have implemented suitable measures to protect your interests; or</li>
				<li>we are aware of or acquire your personal data from our duty, occupation or profession, and we have maintained such new purposes or certain details with
					confidentiality as required by law.
				</li>
			</ul>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">8. What are your rights under the PDPA?</span><br>
			<p class="text-indent">
				Under the PDPA, you have the following rights in respect of your personal data:
			</p>

			1) Right to access <br>

			You have a right to access and obtain a copy of personal data that we hold about you, or you may ask us to disclose the sources of where we obtained your collected
			personal data that you have not given consent.

			We will respond to your request as soon as reasonably possible but not exceeding thirty (30) days after receiving your request.
			<br>

			2) Right to data portability <br>

			You have a right to request us to transfer your collected personal data to other persons/organisations, or request to see your collected personal data that we have
			transferred to other persons/organisations, unless it is impossible due to technical circumstances.
			<br>

			3) Right to object to the processing of your collected personal data <br>

			You have a right to object to the processing of your collected personal data, unless there are circumstances that do not allow you to make the objection. This may
			include when we have compelling legitimate grounds or when the processing of your collected personal data is carried out to comply, exercise or defend legal claims or
			for our public interest.
			<br>

			4) Right to erasure <br>

			You have a right to request us to delete, destroy or anonymise your collected personal data in the following circumstances where:
			<br>
			<ul class="mb-0">
				<li>Your collected personal data is no longer necessary for the purpose for which it was collected, used or disclosed;</li>
				<li>You have withdrawn your consent to which the collection, use or disclosure is based on and we do not have legal grounds to collect, use or disclose your
					collected personal data;
				</li>
				<li>You have objected to the collection, use or disclosure of your collected personal data and we do not have legal grounds to reject your request; and/or</li>
				<li>Your personal data has been unlawfully collected, used or disclosed under the PDPA.</li>
			</ul>

			5) Right to restrict the processing of your collected personal data <br>
			You have a right to request us to restrict the processing of your collected personal data in the circumstances when:

			<ul class="mb-0">
				<li>It is under the pending examination process of checking whether your collected personal data is accurate, up-to-date, complete and not misleading;</li>
				<li>It is your collected personal data that should be deleted or destroyed as it does not comply with the laws, but you request to restrict it instead;</li>
				<li>Your collected personal data is no longer necessary to be retained for the purpose for which it was collected, used or disclosed, but you still have the
					necessity to request the retention for the purposes of the establishment, compliance, exercise of legal claims or the defence of legal claims; and/or
				</li>
				<li>We are pending verification in order to reject the objection request to the collection, use or disclosure of your collected personal data.</li>
			</ul>

			<br>
			6) Right to rectification <br>
			You have a right to rectify inaccurate personal data in order to make it accurate,
			up-to-date, complete and not misleading. If we reject your request, we will record such rejection with reasons.
			<br>
			7) Right to lodge a complaint <br>
			You have a right to make a complaint in the case of where we, our data processors including our employees or contractors do not comply with the PDPA or other
			notifications or announcements under the PDPA.

			<br>
			8) Right to withdraw consent <br>
			You may withdraw your consent at any time, unless we have a lawful basis to deny your request. We would like to also inform you that your consent withdrawal may affect
			our relationships with you or the products and/or the services that will be provided to you by us. This is because, for instance, the personal data, if remaining after
			consent withdrawal, may be insufficient for us to render complete services that you need, or we may need time to request additional information from you.
			<br>
			If you change your mind about how you would like us to have or process your collected personal data and would like to withdraw your consent, you can tell us anytime by
			following our withdrawal process at <span
				class="font-weight-bold">Human Resource Department , TOTO (Thailand) Co.,Ltd. Tel.036-382-750 # 208</span>

			<p class="text-indent">
				Upon our receipt of a request to exercise your rights, we may, in certain cases, request additional information in order to confirm your identity and your rights as
				part of our security measures.
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">9. How long do we keep your collected personal data for?</span><br>
			<p class="text-indent">
				We will only retain your collected personal data for as long as it is necessary for the specific purposes for which the personal data was collected. This means that
				the retention periods will vary according to the type of your collected personal data and the purpose or reason that we collect the personal data. If we do need to
				keep your collected personal data for a longer period to comply with the legal obligation, or if some existing claims or complaints will reasonably require us to
				keep your personal data or for regulatory or technical reasons, we will continue to protect that collected personal data.
			</p>

			<p class="text-indent">
				We have procedures in place regarding our retention periods, which are kept under constant review, taking into account the purposes for processing your collected
				personal data and the lawful basis for doing so.
			</p>

			<p class="text-indent">
				We may need to retain images and video footages from CCTV surveillance systems installed for security and safety of persons and properties within our premises for
				30 days.
			</p>

			<p class="text-indent">
				We will delete, destroy, permanently anonymise or otherwise dispose of all collected personal data at the end of the retention period, or when we must comply with
				your request for erasure of your collected personal data.
			</p>

			<p class="text-indent">
				If you have any questions, please contact us at the provided details in the “How to contact us” section.
			</p>

		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">10. What measures do we use to secure your collected personal data?</span><br>
			<p class="text-indent">
				We adopt security measures to keep your collected personal data safe and secure as well as to prevent loss or damage and illegal or unauthorised collection, access,
				use, modification, correction, disclosure or otherwise processing of your collected personal data. Our security measures which are applied to all types of data
				processing regardless of whether the collected personal data is processed electronically or in paper form, include encryption and other forms of security.
			</p>

			<p class="text-indent">
				We require our employees and third parties who carry out work on our behalf to comply with the PDPA and the appropriate privacy standards including obligations to
				protect any leakage of personal data and to apply appropriate security measures for the processing of personal data.
			</p>

			<p class="text-indent">
				We consistently maintain our security procedures and measures and if an improvement proves to be needed, we will promptly correct or update our security procedures
				and measures taking into account the appropriate physical, technical and organisational security procedures and measures to ensure a level of security of your
				collected personal data appropriate to the respective risk and the ability to ensure the ongoing confidentiality, integrity, availability and resilience of
				processing.
			</p>

			<p class="text-indent">
				We assure that all collected personal data will be stored safely and securely with strict and adequate security standards. If you have a reason to believe that your
				collected personal data has been breached or if you have any questions regarding this Privacy Policy, please contact us at the provided details in the “How to
				contact us?” section.
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">11. When do we make changes to this Privacy Policy?</span><br>
			<p class="text-indent">
				We reserve the right to change, amend or update this Privacy Policy at any time as it deems appropriate. We will notify you of the changes on our website in which
				you can check at any time.
			</p>
		</div>

		<div>
			<br>
			<span class="font-weight-bold" style="text-decoration: underline">12. How to contact us?</span><br>
			<p class="text-indent">
				If you have any comments, suggestions, questions or want to make a complaint or exercise your rights regarding your personal data, please contact us at Human
				Resource Department , TOTO (Thailand) Co.,Ltd. Tel. 036-382-750 # 208 or by email at Mr. Pakkanut Rammanut [pakkanut.rammanut@toto.com] or visit our website at
				https://th.toto.com
			</p>
		</div>

		<div>
			<br>
			<p class="text-right font-weight-bold">
				TOTO (Thailand) Co.,Ltd.
				<br>
				1st June 2022
			</p>
		</div>
		<br><br><br>
	</div>
</template>

<script>
export default {
  name: "PrivacyPolicyTH"
}
</script>

<style scoped>
.text-indent {
    text-indent: 60px;
}

.font-weight-bold {
    font-weight: 600 !important;
}
</style>