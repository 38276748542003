<template>
    <div>
		<div class="register-wrapper">
			<b-link class="brand-logo">
				<vuexy-logo/>
			</b-link>

			<div class="locale-nav">
				<locale/>
			</div>
		</div>
		<b-container class="mt-5 mt-md-3 pt-5">
        <privacy-policy-th v-if="currentLocale === 'th'" />
        <privacy-policy-en v-else />
    </b-container>
    </div>

</template>

<script>
/* eslint-disable global-require */
import {BLink, BButton, BImg, BCard, BCardText, BRow, BCol, BFormGroup, BFormInput, BSpinner, BForm, BFormCheckbox, BFormFile, BOverlay, BContainer} from 'bootstrap-vue'
import PrivacyPolicyTh from "@/components/PrivacyPolicy/PrivacyPolicyTh.vue"
import PrivacyPolicyEn from "@/components/PrivacyPolicy/PrivacyPolicyEn.vue"
import VuexyLogo from '@core/layouts/components/Logo.vue'
import Locale from "@core/layouts/components/app-navbar/components/Locale"
import {localize} from "vee-validate"


export default {
  components: {
    Locale,
    VuexyLogo,
    BLink,
    BButton,
    BImg,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BFormCheckbox,
    BFormFile,
    BSpinner,
    BOverlay,
    BContainer,
    PrivacyPolicyTh,
    PrivacyPolicyEn,
  },
	computed: {
		currentLocale() {
			return this.$i18n.locale
		},
	},
  data: () => ({

  }),

  methods: {}
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
.register-wrapper {
  //display: flex;
  //flex-basis: 100%;
  //min-height: 100vh;
  //width: 100%;
  //align-items: center;
  //justify-content: center;

  //.register-inner {
  //  position: relative;
  //  max-width: 900px;
  //}

  .brand-logo {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 2rem;
    left: 2rem;
    margin: 0;

    .brand-text {
      font-weight: 600;
    }
  }

  .locale-nav {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 1rem;
    right: 2rem;
    margin: 0;

    ::marker {
      color: #fff;
    }
  }
}
</style>
